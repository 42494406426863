<template>
    <div class="addguarant">
        <el-card shadow="never" >
            <template #header>
                <div class="head-bar">
                    保障服务产品
                    <div class="footer">
                        <el-button type="primary" @click="$router.go(-1)" size="small">返回</el-button>
                    </div>
                </div>
               
           </template>
           <div class="content">
                <el-form label-width="150px;">
                    <el-form-item label="设定" label-width="100px">
                        <el-button @click="addnewdetail" size="small" type="primary">添加新分类/品牌/型号</el-button>
                        
                    </el-form-item>
                    <el-form-item label-width="100px" v-loading="load"
                            element-loading-spinner="el-icon-loading"
                            element-loading-text="处理中...">
                        <div class="setting-block" v-for="item,index in rules" :key="item">
                            <el-cascader :options="option" 
                                v-model="item.cateid" 
                                placeholder="选择品类" 
                                clearable
                                style="margin-right:10px;" 
                                :show-all-levels="false"
                                
                                size="small"
                                @change="ClearModel(item)"
                                ></el-cascader>
                            <el-select v-model="item.brandid" placeholder="请选择品牌" style="width:150px;margin-right:10px;" size="small" :disabled='item.cateid?false:true' @change="GetModel(item)">
                                <el-option v-for="item in brands" :key="item" :label="item.Brand" :value="item.ID"></el-option>
                            </el-select>
                            <el-select v-model="item.modelid" placeholder="请选择型号" style="width:150px;margin-right:10px;" size="small" :disabled='item.brandid?false:true'>
                                <el-option v-for="item in item.models" :key="item" :label="item.Model" :value="item.ID"></el-option>
                            </el-select>
                            <el-input-number :controls="false" style="width:100px;margin-right:10px;" placeholder="填写价格" size="small" v-model="item.price"></el-input-number>
                            <el-button @click="oddsave(index)" type="success" size="small" style="margin-right:10px">保存</el-button>
                            <el-button @click="odddel(item.id)" type="danger" size="small" >删除</el-button>
                        </div>
                        
                    </el-form-item>
                    <el-form-item label-width="100px">
                        <el-pagination @current-change="handleCurrentChange"
                                
                                :current-page="curpage"
                                :page-size="15"
                                layout="total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                
                                style="text-align:left"></el-pagination>
                    </el-form-item>
                    
                </el-form>
                
           </div>
           
        </el-card>
    </div>
    <el-dialog title="选择分类/品牌/型号" v-model="dialog" width="40%">
        <div class="setting-block" v-for="item,index in news" :key="item">
            <el-cascader :options="option" 
                v-model="item.cateid" 
                placeholder="选择品类" 
                clearable
                style="margin-right:10px;" 
                :show-all-levels="false"
                
                size="small"
                @change="ClearModel(item)"
                
                ></el-cascader>
            <el-select v-model="item.brandid" placeholder="请选择品牌" style="width:150px;margin-right:10px;" size="small" :disabled='item.cateid?false:true' @change="GetModel(item)" @clear="item.models = []">
                <el-option v-for="item in brands" :key="item" :label="item.Brand" :value="item.ID"></el-option>
            </el-select>
            <el-select v-model="item.modelid" placeholder="请选择型号" style="width:150px;margin-right:10px;" size="small" :disabled='item.brandid?false:true'>
                <el-option v-for="item in item.models" :key="item" :label="item.Model" :value="item.ID"></el-option>
            </el-select>
            <el-input-number :controls="false" style="width:100px;margin-right:10px;" placeholder="填写价格" size="small" v-model="item.price"></el-input-number>
            <el-button @click="addone" type="success" size="small" v-if="index == 0">添加</el-button>
            <el-button @click="del(index)" type="danger" size="small" v-if="index !== 0">删除</el-button>
        </div>
        <template #footer>
            <el-button @click="confirmadd(0,10)" size="small" type="primary">确认</el-button>
            <el-button @click="canceladd" size="small">取消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import qs from 'qs'
import constant from "@/constant"
export default {
    data(){
        return {
            size:10,
            curid:'',
            totalcount:0,
            curpage:1,
            dialog:false,
            load:false,
            option:[],
            brands:[],
            rule:{
                guarantname:[{
                    required: true, message: '请输入保障服务名称',trigger: 'blur'
                }]
            },
            rules:[],
            news:[{
                cateid:[],
                brandid:'',
                modelid:'',
                price:0,
                models:[],
            }],
            
        }
    },
    methods:{
        odddel(id){
            this.$confirm("确定要删除该产品么？","提示",{
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(()=>{
                this.axios.get(constant.odddel,{
                    headers:{
                        "content-type":"application/json"
                    },
                    params:{
                        id:id
                    }
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == 'OK'){
                        this.$message.success("操作成功");
                    }
                });
            }).catch(()=>{
                return false
            });
        },
        oddsave(index){
            console.log(this.rules[index]);
            this.axios.post(constant.saveodd,qs.stringify(this.rules[index]),{
                headers:{
                    "content-type":"application/x-www-form-urlencoded"
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.$message.success("操作成功");
                }else
                    this.$message.error("操作失败");
            });
        },
        del(index){
            this.news.splice(index,1);
        },
        ClearModel(item){
            item.models = "";
            item.modelid = "";
        },
        handleCurrentChange(val){
            this.curpage = val;
            this.getlist(this.curid);
        },
        canceladd(){
            this.dialog = false;
            this.news = [{
                cateid:[],
                brandid:'',
                modelid:'',
                price:0,
                models:[],
            }]
        },
        addnewdetail(){
            this.dialog = true;
            this.news = [{
                cateid:[],
                brandid:'',
                modelid:'',
                price:0,
                models:[],
            }]
        },
        addone(){
            this.news.push({
                cateid:[],
                brandid:'',
                modelid:'',
                price:0,
                models:[],
            });
        },
        submitform(formName){
            console.log("selmerchant");
            console.log(this.selmerchant);
            console.log(JSON.stringify(this.selmerchant));
            this.$refs[formName].validate((valid)=>{
                if(valid){
                    this.axios.post(constant.save_guarant_url,qs.stringify(this.guarantinfo),{
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == "OK"){
                            this.$message.success("操作成功");
                        }else{
                            this.$message.error("操作失败"); 
                        }
                    })
                }else{
                    return false;
                }
            });
        },
        getlist(id){
            this.load = true;
            this.axios.get(constant.getguarantlist,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:id,
                    curpage:this.curpage,
                }
            }).then((response)=>{
                console.log(response.data);
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
                this.rules = response.data.list;
                this.load = false;
            });
        },
        confirmadd(start,end){
            this.load = true;
            let data = this.news.slice(start,end);
            let postdata = Array();
            postdata['list'] = data;
            postdata['id'] = this.curid;
            this.axios.post(constant.saveitem,qs.stringify(postdata),{
                headers:{
                    "content-type":"application/x-www-form-urlencoded"
                },
                
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    console.log("end--" + end);
                    console.log("length--"+this.news.length);
                    if(end < this.news.length){
                        let cur_start = end*1 + 1;
                        let cur_end = start*1 + 10;
                        console.log("start--" + cur_start);
                        console.log("end--" + cur_end);
                        this.confirmadd(cur_start,cur_end);
                    }else{
                        this.load = false;
                        this.$message.success("操作成功");
                        this.$router.go(0);
                    }
                }
            });
        },
        GetModel(item){
            item.modelid = "";
            this.axios.get(constant.get_plan_model_url,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    category:JSON.stringify(item.cateid),
                    brand:item.brandid,
                }
            }).then((response)=>{
                console.log(response.data);
                item.models = response.data;
            });
        }
    },
    created:function(){
        this.curid = this.$route.query.id;
        if(this.curid){
            
           this.getlist(this.curid);
        }
        
        this.axios.get(constant.get_cascader_url,{
            headers:{
                'Content-Type': 'application/json'
            },
        }).then((response)=>{
            console.log(response.data);
            this.option = response.data;
        });

        this.axios.get(constant.get_brand_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                status: 1
            }
        }).then((response)=>{
            console.log(response.data);
            this.brands = response.data;
        });
    }
}
</script>
<style scoped>
.footer {
    display:flex;
    justify-content: center;
}
.setting-block {
    margin-bottom:5px;
}
.head-bar {
    display:flex;
    justify-content:space-between
}
</style>